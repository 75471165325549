<template lang="pug">
el-dialog(:visible.sync="visible" v-if="odr_id" title="Amazon订单信息" :before-close="onClose").pupup-form
    el-form(:inline="true" :model="form" label-width="130px" ref="form" )
        el-form-item(label="订单号")
            el-input(v-model="form.order_id" readonly)
        el-form-item(label="店铺名称")
            el-input(v-model="form.store_name" readonly)
        el-form-item(label="ASIN")
            el-input(v-model="form.asin" readonly)
        el-form-item(label="邮箱" prop="email")
            el-input(v-model="form.email" readonly)
        el-form-item(label="SKU")
            el-input(v-model="form.sku" readonly)
        el-form-item(label="姓名")
            el-input(v-model="form.name" readonly) 
        el-form-item(label="购买价格" prop="item_price_per_unit" )
            el-input(v-model="form.item_price_per_unit" readonly)
              template(slot="prepend") $
        el-form-item(label="地址")
            el-input(type="textarea" v-model="form.address" readonly)
        el-form-item(label="备注")
            el-input(type="textarea" v-model="form.remark" readonly)
        el-form-item(label="电话")
            el-input(v-model="form.phone" readonly)
        el-form-item(label="是否测评")
            el-input(:value="form.is_evaluation|formatBoolean" readonly)
    div(slot="footer").dialog-footer
        el-button(@click="onClose") 关闭
</template>

<script>
import * as order from "../../../api/order";
export default {
  name: "view-amazon",
  props: ["odr_id"],
  data: () => ({
    visible: false,
    form: {}
  }),
  methods: {
    onClose() {
      this.visible = false;
      this.odr_id == 0;
      this.$emit("update:odr_id", this.tabData);
    }
  },
  watch: {
    async odr_id(odr_id) {
      if (odr_id > 0) {
        let odr = await order.getAmazonOrderByPK({ odr_id: odr_id });
        if (odr.data.errno == 200) {
          this.form = odr.data.data;
          this.visible = true;
        }
      }
    }
  }
};
</script>
