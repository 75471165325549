<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-select(v-model="listQuery.store_name" placeholder="选择店铺" clearable style="width:120px").filter-item.ml-5
              el-option( v-for="item in storeOptions" :key="item.value" :label="item.label" :value="item.value")
            el-input(v-model="listQuery.keyword" clearable style="width: 200px;" placeholder="订单号/ASIN/邮箱").filter-item.ml-5
            el-date-picker(v-model="listQuery.date" type="daterange"  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期").filter-item.ml-5
            el-button(v-permission="['order:warranty-order:list']" type="primary" icon="el-icon-search" @click="onFilter").filter-item.ml-5 查找
            el-button(v-permission="['order:warranty-order:export']" type="primary" icon="el-icon-upload2" @click="onExport" :loading="downloadLoading").filter-item.float-right 导出Excel

        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="right" label="ASIN" prop="asin" sortable width="120px")
            el-table-column(align="right" label="SKU" prop="sku" sortable width="120px")
            el-table-column(align="right" label="店铺名称" prop="store_name" sortable width="120px")
            el-table-column(align="right" label="Amazon订单ID" prop="order_id" sortable width="180px")
              template(slot-scope="scope")
                el-link(@click="onViewAmazonOrder(scope.row)") {{scope.row.order_id}}
            el-table-column(align="right" label="邮箱" prop="email" sortable)
            el-table-column(align="right" label="购买时间" prop="shipment_date" sortable)
                template(slot-scope="scope")
                    span {{scope.row.purchase_date | formatDateAndTime}}
            el-table-column(align="right" label="激活时间" prop="active_date" sortable)
                template(slot-scope="scope")
                    span {{scope.row.active_date | formatDateAndTime}}
            el-table-column(align="right" label="备注" prop="remark" sortable)
            el-table-column(align="right" label="来源" prop="hash_code" sortable)

            el-table-column(align="center" label="操作").small-padding.fixed-width
                template(slot-scope="scope")
                    el-link(v-permission="['order:warranty-order:view']" type="primary" size="mini" @click="onView(scope.row)") 详情
                    el-link.opt-link(v-permission="['order:warranty-order:edit']" type="primary" size="mini" @click="onUpdate(scope.row)") 编辑
                    el-link.opt-link(v-permission="['order:warranty-order:delete']" type="primary" size="mini" @click="onDelete(scope.row)") 删除

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList")

        //- 弹出层 - 修改订单
        el-dialog(:visible.sync="dialogForm" title="普通激活保修订单" :close-on-click-modal="false").pupup-form
          el-form(:inline="true" :model="form" label-width="130px" ref="form" :rules="formRules" )
            el-form-item(label="Amazon订单ID")
              el-input(v-model="form.order_id" readonly)
            el-form-item(label="店铺名称")
              el-input(v-model="form.store_name" readonly)
            el-form-item(label="ASIN")
              el-input(v-model="form.asin" readonly)
            el-form-item(label="邮箱" prop="email")
              el-input(v-model="form.email" readonly)
            el-form-item(label="SKU" )
              el-input(v-model="form.sku" readonly)
            el-form-item(label="姓名")
              el-input(v-model="form.name" readonly)
            el-form-item(label="购买价格")
              el-input(v-model="form.item_price_per_unit" readonly)
            el-form-item(label="地址")
              el-input(type="textarea" v-model="form.address" :readonly="formMode!='edit'")
            el-form-item(label="备注")
              el-input(type="textarea" v-model="form.remark" :readonly="formMode!='edit'")
            el-form-item(label="电话")
              el-input(v-model="form.phone" :readonly="formMode!='edit'")
            el-form-item(label="来源")
              el-input(v-model="form.hash_code" readonly)
          div(slot="footer").dialog-footer
            el-button(@click="onUpdateCancel") 取消
            el-button(v-if="formMode=='edit'" v-permission="['order:warranty-order:edit']" :loading="form.loading" @click="onUpdateSave" type="primary") 保存

        //- 弹出层
        view-amazon-order(:odr_id.sync="current_odr_id")
</template>

<script>
import * as order from "../../api/order";
import { parseTime } from "../../utils";
import { getStoreOptions } from "../../api/store";
import _ from "lodash";
import ViewAmazonOrder from "./components/view-amazon-order";
import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

export default {
  name: "warranty-order-list",
  components: { Pagination, ViewAmazonOrder },
  data() {
    return {
      storeOptions: [],
      list: null,
      total: 0,
      listLoading: true,
      downloadLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: undefined,
        date: undefined,
        sort: "creation_date",
        order: "desc"
      },
      dialogForm: false,
      form: {},
      formMode: "edit",
      formRules: {
        email: {
          type: "email",
          message: "输入正验的邮箱",
          trigger: "change"
        },
        item_price_per_unit: [
          {
            required: true,
            pattern: /^\d*(\.\d{1,2})?$/,
            message: "请输入两位小数的数字",
            trigger: "change"
          }
        ]
      },
      current_odr_id: 0
    };
  },
  created() {
    this.getStoreOptions();
    this.getList();
  },
  methods: {
    async getStoreOptions() {
      let resp = await getStoreOptions();
      this.storeOptions = resp.data.data;
    },
    async getList() {
      this.listLoading = true;
      try {
        const resp = await order.getWarrantyOrderList(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    async onUpdate(row) {
      const res = await order.getWarrantyOrderByPK({ wao_id: row.wao_id });
      this.form = res.data.data;
      this.formMode = "edit";
      this.dialogForm = true;
    },
    async onView(row) {
      const res = await order.getWarrantyOrderByPK({ wao_id: row.wao_id });
      this.form = res.data.data;
      this.formMode = "view";
      this.dialogForm = true;
    },
    async onUpdateCancel() {
      this.dialogForm = false;
    },
    async onUpdateSave() {
      try {
        await this.$refs.form.validate();
        const res = await order.updateWarrantyOrderByPK(this.form);
        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `订单:${this.form.order_id} 信息修改成功`
          });
          this.dialogForm = false;
          this.getList();
        }
      } catch (error) {
        return false;
      }
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将删除订单:" + row.order_id + ", 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        const res = await order.deleteWarrantyOrderByPK(row);

        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `订单:${row.order_id} 信息删除成功`
          });
        }
        this.getList();
      } catch (e) {
        return false;
      }
    },
    onViewAmazonOrder(row) {
      this.current_odr_id = row.odr_id;
    },
    onFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j.indexOf("_date") > -1) {
            return !v[j] || parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    async onExport() {
      this.downloadLoading = true;
      const critria = _.assign(this.listQuery, { limit: 10000, page: 1 });
      const resp = await order.getWarrantyOrderList(critria);
      const list = resp.data.data.items;
      import("@/vendor/Export2Excel").then(excel => {
        const tHeader = [
          "ASIN",
          "SKU",
          "店铺名称",
          "订单ID",
          "邮箱",
          "购买时间",
          "激活时间",
          "购买价格",
          "姓名",
          "电话",
          "地址",
          "备注",
          "来源"
        ];
        const filterVal = [
          "asin",
          "sku",
          "store_name",
          "order_id",
          "email",
          "purchase_date",
          "active_date",
          "item_price_per_unit",
          "name",
          "phone",
          "address",
          "remark",
          "hash_code"
        ];
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename:
            "普通激活保修订单_" +
            parseTime(new Date(), "{y}-{m}-{d}_{h}:{i}:{s}"),
          autoWidth: true,
          bookType: "xlsx"
        });
        this.downloadLoading = false;
      });
    }
  }
};
</script>
