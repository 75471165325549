import request from "@/utils/request";

// 新建store
export function createStore(data) {
  return request({
    url: "/store/create-store",
    method: "post",
    data
  });
}

// 取得store列表
export function getStoreList(data) {
  return request({
    url: "/store/list-store",
    method: "post",
    data
  });
}

// 取得store
export function getStoreByPK(data) {
  return request({
    url: "/store/get-store-by-pk",
    method: "post",
    data
  });
}

// 取得store
export function updateStoreByPK(data) {
  return request({
    url: "/store/update-store-by-pk",
    method: "post",
    data
  });
}
// 删除store
export function deleteStoreByPK(data) {
  return request({
    url: "/store/delete-store-by-pk",
    method: "post",
    data
  });
}

// store下拉
export function getStoreOptions() {
  return request({
    url: "/store/get-store-options",
    method: "post"
  });
}
